<template>
  <div>
    <!-- if this is active route (e.g. /locations) -->
    <template v-if="isCurrentRoute">
      <h2>{{ $t("views.locations.addresses.title") }}</h2>

      <b-row class="mb-3" align-h="between" v-if="!isReadOnly">
        <b-col cols="auto"></b-col>
        <b-col cols="auto">
          <b-button variant="outline-primary" @click="navigate('Locations.Addresses.Add')"
            ><b-icon icon="plus" /> {{ $t("views.locations.addresses.button.addaddress") }}</b-button
          >
        </b-col>
      </b-row>

      <entity-entry-table :entityKey="eCurrent.key" :entityBy="eParent" :tableSettings="table">
        <template #cell(type)="data">
          <b-badge variant="dark">{{ data.cell.value }}</b-badge>
        </template>
      </entity-entry-table>
    </template>

    <!-- if subroute is active route (e.g. /locations/detail) -->
    <section v-else>
      <router-view />
    </section>
  </div>
</template>

<script>
import { CLASS_TABLE_FIELD_KEY_ALL } from "@/constants";

import { TableHelper } from "@/assets/js/helper/table";

import MixinEntityBase from "@/components/mixins/EntityBase";
import EntityEntryTable from "@/components/entity/EntryTable";

const ENTITY_KEY = "locationaddresses";

export default {
  name: "Locations.Addresses",
  mixins: [MixinEntityBase],
  components: {
    EntityEntryTable,
  },
  data() {
    return {
      entityKey: ENTITY_KEY,
      table: {
        helper: TableHelper,
        mapper: this.tableEntitiesMapper,
        options: {
          fields: {
            includes: [],
            excludes: [],
            sorting: ["type", "street", "zip", "city", "canton", "country", "status"],
            sortable: [CLASS_TABLE_FIELD_KEY_ALL],
            labelKey: `views.${this.$options.name.toLowerCase()}.table.label.{field}`,
            label: {},
            variant: {},
            class: {
              type: "cell-min",
              status: "cell-min cell-align-right",
            },
            thClass: {
              type: "cell-nowrap",
            },
            tdClass: {},
          },
        },
        events: {
          rowClicked: this.tableRowClicked,
        },
      },
    };
  },
  computed: {
    isCurrentRoute() {
      return Object.values(this.$route.matched[this.$route.matched.length - 1].components).find((c) => c.name === this.$options.name) !== undefined;
    },
    entity() {
      return (this.$store.getters[`${this.eParent.key}/getEntity`](this.eParent.id) || {}).value || {};
    },
    isReadOnly() {
      return this.$store.getters["profile/isReadOnlyTenant"](this.entity.tenantId);
    },
  },
  methods: {
    tableEntitiesMapper(entities) {
      return entities.map((e) =>
        Object.assign(
          {
            $routerLink: {
              name: `${this.$options.name}.Detail`,
              params: {
                [this.eCurrent.paramKey]: e.id,
              },
            },
          },
          e
        )
      );
    },
    tableRowClicked(entity) {
      if (!this.isReadOnly) {
        this.$router.push(entity.$routerLink);
      }
    },
  },
  created() {
    TableHelper.rowClassHook((entity) => (entity.$routerLink ? "has-routerlink" : null));
  },
};
</script>

<style lang="scss"></style>
